import {Component} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import {DecimalPipe, NgIf} from "@angular/common";
import {TranslocoDirective} from "@ngneat/transloco";
import {ReactiveFormsModule} from "@angular/forms";
import {HintComponent} from "../../../../shared/components/hint/hint.component";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {WizardService} from "../../../../shared/services/wizard-service/wizard.service";
import {MatInput, MatInputModule} from "@angular/material/input";
import {WizardFooterComponent} from "../../wizard-footer/wizard-footer.component";
import {FormService} from "../../../../shared/services/form-service/form.service";
import {ValidationService} from "../../../../shared/services/validation-service";
import {CurrencyFormatDirective} from "../../../../shared/directives/currency-directive";
import {NumericFieldDirective} from "../../../../shared/directives/numeric-field-directive";

@Component({
  selector: 'app-sponsoring-value-one-year',
  standalone: true,
  imports: [
    MatFormField,
    MatInputModule,
    NgIf,
    TranslocoDirective,
    ReactiveFormsModule,
    HintComponent,
    MatRadioGroup,
    MatRadioButton,
    MatInput,
    WizardFooterComponent,
    CurrencyFormatDirective,
    NumericFieldDirective,
  ],
  providers: [DecimalPipe],
  templateUrl: './sponsoring-value-one-year.component.html',
  styleUrl: './sponsoring-value-one-year.component.scss',
})
export class SponsoringValueOneYearComponent {
  form = this.formService.buildForm();

  constructor(
    public wizardService: WizardService,
    public formService: FormService,
    public validationService: ValidationService) {
    this.formService.initializeForm(this.form);
    this.disableFormControl();
  }

  changeTaxOption(event: MatRadioChange): void {
    this.disableFormControl();
    this.formService.setFormData(this.form);
  }

  disableFormControl(): void {
    if (this.form.get('sponsoringValue.taxFree')?.value) {
      this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.disable();
      localStorage.removeItem('form-data.sponsoringValue.sponsoringValueGrossAmountFirstYear');
    } else {
      this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.enable();
      localStorage.removeItem('form-data.sponsoringValue.sponsoringValueGrossAmountFirstYear');
    }
  }
}
