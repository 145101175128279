import {Component, OnDestroy, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {translate, TranslocoModule} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {MatCalendar, MatDatepicker, MatDatepickerModule} from "@angular/material/datepicker";
import {MatCard} from "@angular/material/card";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {Subscription} from "rxjs";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {FormService} from "../../../shared/services/form-service/form.service";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {ValidationService} from "../../../shared/services/validation-service";
import {MatError} from "@angular/material/form-field";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-wizard-sponsoring-start',
    standalone: true,
    imports: [
        TextButtonComponent,
        TranslocoModule,
        WizardHeaderComponent,
        HintComponent,
        MatDatepicker,
        MatDatepickerModule,
        MatCard,
        MatCalendar,
        ReactiveFormsModule,
        WizardFooterComponent,
        StepperComponent,
        MatError,
        NgIf
    ],
    providers: [
        MatDatepickerModule,
    ],
    templateUrl: './sponsoring-start-date.component.html',
    styleUrl: './sponsoring-start-date.component.scss'
})
export class SponsoringStartDateComponent implements OnInit, OnDestroy {
    form = this.formService.buildForm();
    currentDate = new Date();
    sponsoringValue: FormGroup = this.form.get('sponsoringValue') as FormGroup;

    private subscriptions: Subscription = new Subscription();

    constructor(public wizardService: WizardService, public formService: FormService, public validationService: ValidationService) {
        this.formService.initializeForm(this.form);
    }

    ngOnInit() {
        const sponsoringValueSub = this.sponsoringValue.valueChanges.subscribe(() => {
            this.disableDateFields();
        });
        this.subscriptions.add(sponsoringValueSub);
        this.disableDateFields();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    disableDateFields(): void {
        this.currentDate = new Date();
        if(this.isSponsoringValueMoreThan20000()){
          this.currentDate.setDate(this.currentDate.getDate() + (6 * 7));
        } else {
          this.currentDate.setDate(this.currentDate.getDate() + (15));
        }
    }

    updateFormDate(date: Date | null) {
        if (date) {
            const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0); // Set time to noon
            this.form.get('sponsoringValue.sponsoringStartDate')?.setValue(adjustedDate);
        } else {
            this.form.get('sponsoringValue.sponsoringStartDate')?.setValue(null);
        }
    }

    setDatePickerDescriptionText(): string {
        return this.isSponsoringValueMoreThan20000() ?
            translate('wizard.sponsoringStart.descriptionSponsoringMore') :
            translate('wizard.sponsoringStart.descriptionSponsoringOneYear')
    }


    private isSponsoringValueMoreThan20000(): boolean {
      return this.sponsoringValue && (
          parseInt(this.sponsoringValue.get('sponsoringValueGrossAmountFirstYear')?.value?.replace('.', '')) > 20000 ||
          parseInt(this.sponsoringValue.get('sponsoringValueNetAmountFirstYear')?.value?.replace('.', '')) > 20000 ||
          parseInt(this.sponsoringValue.get('sponsoringValueGrossAmountSecondYear')?.value?.replace('.', '')) > 20000 ||
          parseInt(this.sponsoringValue.get('sponsoringValueNetAmountSecondYear')?.value?.replace('.', '')) > 20000);
    }
}
