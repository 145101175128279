import {Directive, HostListener, ElementRef, Renderer2, OnInit, Optional} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[appCurrencyFormat]',
  standalone: true,
})
export class CurrencyFormatDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngControl: NgControl
  ) {}

  ngOnInit() {
    this.formatValue();
  }

  @HostListener('blur')
  onBlur(): void {
    this.formatValue();
  }

  formatValue(): void {
    let currentValue = this.el.nativeElement.value;

    if (currentValue) {
      currentValue = currentValue.replace(/\./g, '').replace(',', '.');
      const numericValue = parseFloat(currentValue);

      if (!isNaN(numericValue)) {
        const formattedValue = this.formatToCurrency(numericValue);
        this.ngControl.control?.setValue(formattedValue, { emitEvent: false });

        // Update the input element's displayed value
        const inputElement = this.ngControl.valueAccessor;
        (inputElement as any)?.setValue?.(formattedValue); // For consistency
      } else {
        this.renderer.setProperty(this.el.nativeElement, 'value', '');
      }
    }
  }

  formatToCurrency(value: number): string {
    const stringValue = String(value);
    const normalizedValue = stringValue.replace(/\./g, '').replace(',', '.');
    const numericValue = typeof value === 'number' ? value : parseFloat(normalizedValue);

    const [integerPart, decimalPart] = numericValue.toFixed(2).split('.');
    const withThousandSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${withThousandSeparators},${decimalPart}`;
  }
}
