import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  KeyValueCardComponentComponent
} from "../../../shared/components/key-value-card-component/key-value-card-component.component";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule
} from "@angular/forms";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {MatCard} from "@angular/material/card";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {MatDialog} from "@angular/material/dialog";
import {RejectionModalComponent} from "../rejection-modal/rejection-modal.component";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {
  FinancialKeyComponent
} from "../../../shared/components/financial-assistance-table-component/financial-key/financial-key.component";
import {RdConfirmationComponentComponent} from "../rd-confirmation-component/rd-confirmation-component.component";
import {
  GlvkfConfirmationComponentComponent
} from "../glvkf-confirmation-component/glvkf-confirmation-component.component";
import {
  ZuschussConfirmationComponentComponent
} from "../zuschuss-confirmation-component/zuschuss-confirmation-component.component";
import {IconService} from "../../../shared/services/IconService";
import {UserDto} from "../../../api/userDto";
import {Subscription} from "rxjs";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {VkfConfirmationComponentComponent} from "../vkf-confirmation-component/vkf-confirmation-component.component";
import {CurrencyFormatDirective} from "../../../shared/directives/currency-directive";


@Component({
  selector: 'app-vkf-detailoverview',
  standalone: true,
  imports: [
    KeyValueCardComponentComponent,
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    ReactiveFormsModule,
    HintComponent,
    MatCard,
    NgForOf,
    NgIf,
    DatePipe,
    FormsModule,
    FileViewerComponent,
    FinancialKeyComponent,
    RdConfirmationComponentComponent,
    GlvkfConfirmationComponentComponent,
    ZuschussConfirmationComponentComponent,
    MatProgressSpinner,
    VkfConfirmationComponentComponent,
  ],
  providers: [CurrencyFormatDirective],
  templateUrl: './vkf-detailoverview.component.html',
  styleUrl: './vkf-detailoverview.component.scss'
})
export class VkfDetailoverviewComponent implements OnInit, OnDestroy{
  form = this.formService.buildForm();

  internalNote = '';
  user: UserDto | null = null;

  comments: { [key: string]: Array<{ author: string; date: string; sonstiges: string }> } = {};
  editMode = false;
  applicationId = '';
  applicationData: any = null;
  stateHistoryData: any;
  correctionsButtonisReadOnly = true;
  selectedFiles: { documents: any[] } = { documents: [] };
  fundingFiles: { documents: any[] } = { documents: [] };
  isLoading: boolean = false;

  commentForm = this.fb.group({
    comments: this.fb.group({
      applicant: this.fb.control(''),
      sponsoringValue: this.fb.control(''),
      financialAssistance: this.fb.control(''),
      entitlements: this.fb.control(''),
      sponsored: this.fb.control(''),
      dataUpload: this.fb.control('')
    })
  });

  sections = [
    { id: 'sponsoringValue', title: 'keyValueTitles.sponsoringValue', dataKey: 'sponsoringValue' },
    { id: 'applicant', title: 'keyValueTitles.applicant', dataKey: 'applicant' },
    { id: 'sponsored', title: 'keyValueTitles.sponsored', dataKey: 'sponsored' },
    { id: 'financialAssistance', title: 'keyValueTitles.financialAssistance', dataKey: 'financialAssistance' },
    { id: 'entitlements', title: 'keyValueTitles.entitlements', dataKey: 'entitlements' },
    { id: 'dataUpload', title: 'keyValueTitles.dataUpload', dataKey: 'dataUpload' }
  ];
  private subscriptions = new Subscription();

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public dataService: DataService,
              public IconService: IconService,
              public router: Router,
              public currencyFormat: CurrencyFormatDirective,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.formService.initializeForm(this.form);

    const userSub = this.dataService.getUser().subscribe((result) => {
      this.user = result;
    });
    this.subscriptions.add(userSub);

    const commentFormSub = this.commentForm?.valueChanges.subscribe(value => {
      this.correctionsButtonisReadOnly = !value;
    });
    this.subscriptions.add(commentFormSub);

    this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if(state.applicationId){
        this.applicationId = state.applicationId.toString();
        this.getDataForSelectedApplication(state.applicationId);
        this.getCommentsForSelectedApplication(state.applicationId);
        this.getZuschussDocuments();
        this.getGeneratedDocumentsForApplication(state.applicationId);
        this.getStateHistory(state.applicationId);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getZuschussDocuments(): any {
    this.dataService.getZuschussDocuments(this.applicationId).subscribe((result) => {
      this.fundingFiles = result;
    });
  }

  isButtonPrimary(index: number): boolean {
    const buttons = this.stateTextService.returnTextForVkf(this.applicationData?.sponsoringValue?.application_state).button;
    return buttons.length <= 1 || index !== 0;
  }

  getGeneratedDocumentsForApplication(applicationId: string): any {
    this.dataService.getContractDocuments(this.applicationId).subscribe((result) => {
      this.selectedFiles = result;
    });
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
    });
  }

  getCommentsForSelectedApplication(applicationId: string): void {
    this.dataService.getCommentsForApplicationById(applicationId).subscribe((result) => {
      this.comments = result;
    });
  }

  getCommentsForSection(section: string): Array<{ authorName: string; created_at: string; content: string }> | null {
    let result: any;
    if(this.comments['comments']){
      Object.entries(this.comments['comments']).forEach(([key, value]) => {
        if(key === section){
          result = value;
        }
      });
    }
    return result;
  }

  getStateHistory(applicationId: string): any {
    this.dataService.getStateHistory(this.applicationId).subscribe((result)=>{
      this.stateHistoryData = result;
    });
  }

  showSponsoringDocument(): boolean {
    const keys = Object.keys(this.selectedFiles.documents)
    return keys.includes('contract') || keys.includes('signed_contract') || keys.includes('attachment');
  }

  showCommentField(sectionId: string): boolean {
    if(sectionId === 'sponsoringValue') {
      return false;
    } else if(sectionId === 'dataUpload' && !Object.values(this.selectedFiles.documents).includes('sonstiges_antrag')) {
      return false;
    } else {
      return true;
    }
  }

  returnStateValue(): string {
    if(
      this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.APPROVED_ADD_CONTRACT ||
      this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.CORRECTION_CHECK ||
      this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.CHECK_RD ||
      this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.APPROVAL_NO_CONTRACT||
      this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.ZUSCHUSS ||
      (this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.CHECK_GLVKF &&
        this.applicationData?.sponsoringValue?.application_state !== applicationStateEnum.IN_WORK_ZUSCHUSS)
    ) {
      return this.applicationData?.sponsoringValue?.application_state_label
    }  else {
      return this.applicationData?.sponsoringValue?.application_state
    }
  }

  changeStateToCorrectionsNeeded(action: string): void {
    if(action === 'correctionNeeded'){
      this.dataService.postComment(this.applicationId, this.postComment()).subscribe({
        next: () => {
          this.dataService.changeStateOfApplication(this.applicationId, action, '').subscribe({
            next: () => {
              this.navigateToDashboard();
            },
            error: (err) => {
              console.error('Status konnte nicht geändert werden', err);
            }
          });
        },
        error: (err) => {
          console.error('Fehler beim Speichern der Kommentare:', err);
        }
      });

    } else {
      this.dataService.changeStateOfApplication(this.applicationId, action, '').subscribe({
        next: () => {
          this.navigateToDashboard();
        },
        error: (err) => {
          console.error('Status konnte nicht geändert werden', err);
        }
      });
    }
  }
  changeState(action: string, reason?:  string): void {
    this.isLoading = true;
    this.dataService.changeStateOfApplication(this.applicationId, action, reason ?? '').subscribe({
      next: () => {
        this.isLoading = false;
        this.navigateToDashboard();
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }

  openRejectionModal(action: string) {
    const dialogRef = this.dialog.open(RejectionModalComponent, {
      width: '920px',
      height:'max-content',
    });
    dialogRef.afterClosed().subscribe( (rejectionReason) =>  {
      if(rejectionReason){
        this.dataService.changeStateOfApplication(this.applicationId, action, rejectionReason).subscribe({
          next: () => {
            this.navigateToDashboard();
          },
          error: (err) => {
            console.error('Status konnte nicht geändert werden', err);
          }
        });
        this.navigateToDashboard();
      }
    });
  }

  saveInteranlNote(note: string): void {
    this.applicationData?.internalNotes.unshift({
      authorName: this.user?.firstName + ' ' + this.user?.lastName,
      note: note,
      created_at: new Date().toString(),
    });

    this.dataService.postInternalNotes(this.applicationId, {note:note, rating: 'good'}).subscribe({
      next: () => {
        this.internalNote = ''
      },
      error: (err) => {
        console.error('Interne Anmerkung konnte nicht geändert werden', err);
      }
    });
  }

  postComment(): object  {
    const postCommentRequest = {
      applicant: {
        comments: [this.commentForm.get('comments.applicant')?.value],
      },
      sponsoringValue: {
        comments: [this.commentForm.get('comments.sponsoringValue')?.value],
      },
      financialAssistance: {
        comments: [this.commentForm.get('comments.financialAssistance')?.value],
      },
      entitlements: {
        comments: [this.commentForm.get('comments.entitlements')?.value],
      },
      sponsored: {
        comments: [this.commentForm.get('comments.sponsored')?.value],
      },
      dataUpload: {
        comments: [this.commentForm.get('comments.dataUpload')?.value],
      },
      contractCheck: {
        comments: [this.commentForm.get('comments.dataUpload')?.value],
      },
      fundingCheck: {
        comments: [this.commentForm.get('comments.dataUpload')?.value],
      }
    }
    return postCommentRequest
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  switchMode(disabledButton: boolean, index:number) {
    if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_APPROVED_GLVKF){
      this.changeState('accepted');
    }
    else if(this.applicationData?.sponsoringValue?.application_state_label === applicationStateEnum.CONTRACT_CHECK){
      this.router.navigate(['/contract-check'], { state: { applicationId: this.applicationId } });
    }
    else if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.ZUSCHUSS_UPLOADED){
      this.router.navigate(['/zuschuss-overview'], { state: { applicationId: this.applicationId } });
    }
    else if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_RD_LEITER){
      if(index === 0){
        this.openRejectionModal('rejected');
      } else {
        this.changeState('accepted');
      }
    }
    else if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.REJECTED_RD_LEITER){
      this.changeState('rejected');
    }
    else if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.REJECTED_GLVKF){
      this.changeState('rejected', this.stateHistoryData?.status_history.glvkf.Begründung );
    }
    else if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_GLVKF){
      this.changeState('accepted');
    }
    else if(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_OD_LEAD){
      this.changeState('accepted');
    }
    else {
      if(!disabledButton &&
        (this.applicationData?.sponsoringValue?.application_state !== applicationStateEnum.APPROVED_RD_LEITER &&
          this.applicationData?.sponsoringValue?.application_state !== applicationStateEnum.REJECTED_GLVKF &&
          this.applicationData?.sponsoringValue?.application_state !== applicationStateEnum.APPROVED_GLVKF)
      ){
        this.editMode = !this.editMode;
      }
    }
  }
  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  getFunding(): string {
    return this.applicationData?.sponsoringValue?.funding_amount ? this.currencyFormat.formatToCurrency(this.applicationData?.sponsoringValue?.funding_amount) : '';
  }

  isFundingReduced(): boolean {
    return this.applicationData?.sponsoringValue?.is_reduced_funding_amount;
  }

  protected readonly Object = Object;
}
