<ng-container [formGroup]="form" *transloco="let t">
  <div class="dialog-header">
    <h3>{{ t('wizardTitles.' + groupName) }}</h3>
    <mat-icon svgIcon="close-icon" (click)="closeDialog()"></mat-icon>
  </div>
  <div class="dialog-content container">
    <div class="row">
      <ng-container *ngIf="groupName === 'financialAssistance' else Default">
        <div class="financialContainer">
          <app-wizard-sponsoring-financial-assistance (financialAssistanceUpdated)="onSaveInFinancialAssistance($event)"
                                                      [editMode]="true"></app-wizard-sponsoring-financial-assistance>
        </div>
      </ng-container>

      <ng-template #Default>
        <ng-container *ngFor="let key of keyList">
          <ng-container  *ngIf="key.field === 'hospitality.hospitalityService' || key.field === 'hospitality.hospitalityValue' else generatedFields">
            <ng-container *ngIf="key.field === 'hospitality.hospitalityService'">
              <div class="col-lg-6">
                <mat-form-field class="col-lg-6 full-width"  appearance="fill">
                  <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
                  <input [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" matInput [formControl]="key.data"/>
                  <mat-error *ngIf="key.data.errors"><span>{{ t('error.' + getFormControlErrors(key.data, key.field)) }}</span></mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="key.field === 'hospitality.hospitalityValue'">
              <div class="col-lg-6">
                <mat-form-field class="col-lg-6 full-width"  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" appearance="fill">
                  <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
                  <input  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" type="text" matInput [formControl]="key.data" appNumericInput appCurrencyFormat/>
                  <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>

          <ng-template #generatedFields>
            <ng-container [ngSwitch]="key.type">
              <ng-container *ngSwitchCase="'string'">
                <div class="col-lg-6">
                  <mat-form-field class="col-lg-6 full-width"  appearance="fill">
                    <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
                    <ng-container *ngIf="key.field.startsWith('services') else defaultString">
                      <textarea placeholder="min. 10 Zeichen" [minlength]="10" matInput [formControl]="key.data"></textarea>
                    </ng-container>
                    <ng-template #defaultString>
                      <input [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" matInput [formControl]="key.data"/>
                    </ng-template>
                    <mat-error *ngIf="key.data.errors">
                      <span>{{ t('error.' + getFormControlErrors(key.data, key.field)) }}</span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <ng-container *ngIf="key.field === 'hospitality.isHospitality'">
                <app-icon-text-button class="element-space" (click)="addNewLeistung()" [textColor]="'white'" [iconName]="'add-icon'" [buttonText]="t('buttonText.addMore')"></app-icon-text-button>
              </ng-container>

              <ng-container *ngSwitchCase="'number'">
                <div class="col-lg-6">
                  <mat-form-field class="col-lg-6 full-width"  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" appearance="fill">
                    <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
                    <input  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" type="number" matInput [formControl]="key.data"/>
                    <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'boolean'">
                <div class="radio-button-group col-lg-12">
                  <label class="font-c3 radio-label">{{ t('formFieldMapping.' + key.field) }}</label>

                  <ng-container *ngIf="(key.field === 'firstYear.fullPayed' && 'secondYear.fullPayed') else defaultCase">
                    <mat-radio-group class="radio-button" [formControl]="key.data"
                                     (change)="this.financialAssistanceService.changePaymentMethod($event, key.field)">

                      <mat-radio-button [value]="true"><p>Gesamtbetrag</p></mat-radio-button>
                      <mat-radio-button [value]="false"><p>In Raten</p></mat-radio-button>
                      <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
                    </mat-radio-group>
                  </ng-container>

                  <ng-template #defaultCase>
                    <mat-radio-group class="radio-button" [formControl]="key.data" (change)="changeValue($event)">
                      <mat-radio-button [value]="true"><p>{{t('common.yes')}}</p></mat-radio-button>
                      <mat-radio-button [value]="false"><p>{{t('common.no')}}</p></mat-radio-button>
                      <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
                    </mat-radio-group>
                  </ng-template>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'date'">
                <div class="col-lg-6">
                  <mat-form-field class="col-lg-6 full-width" appearance="fill">
                    <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
                    <input matInput [matDatepicker]="picker" [formControl]="key.data"/>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'object'">
                <ng-container>
                  <ng-container *ngIf="key.field === 'hospitality.hospitalityService'">
                    <div class="col-lg-6">
                      <mat-form-field class="col-lg-6 full-width"  appearance="fill">
                        <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
                        <input [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" matInput [formControl]="key.data"/>
                        <mat-error *ngIf="key.data.errors"><span>{{ t('error.' + getFormControlErrors(key.data, key.field)) }}</span></mat-error>
                      </mat-form-field>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="key.field === 'hospitality.hospitalityValue'">
                    <div class="col-lg-6">
                      <mat-form-field class="col-lg-6 full-width"  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" appearance="fill">
                        <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
                        <input  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" type="text" matInput [formControl]="key.data" appNumericInput appCurrencyFormat/>
                        <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>

        </ng-container>
        <div class="button-group col-lg-12">
          <app-text-button [isButtonReadOnly]="isFormInvalid(form)" [isPrimaryButton]="true" buttonText="Übernehmen" (click)="saveFormValues()"></app-text-button>
        </div>
      </ng-template>
      <div>
    </div>
  </div>

  </div>
</ng-container>
